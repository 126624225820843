import { useMaHeroQuery } from "../../../hooks/useMaHeroQuery"

const MaHeroMask = () => {
  
  const {
    wpPage: { marketingAutomation: data },
  } = useMaHeroQuery()

  const imageMask = data.heroMask.localFile.publicURL


  return (
    imageMask
  )
}

export default MaHeroMask
