import React from "react"
import { getImage } from "gatsby-plugin-image"
import { useMaIntroQuery } from "../../../hooks/useMaIntroQuery"
import { Wrapper, StyledImg } from "./MaIntro.styles"

const MaIntro = () => {
  
  const {
    wpPage: { marketingAutomation: data },
  } = useMaIntroQuery()

  const introText = data.introText
  const imageData = getImage(data.introImage.localFile)
  
  return (
    <Wrapper>
      <div className="container">
        <div className="columns">
            <div className="column">
                <div dangerouslySetInnerHTML={{ __html: introText }} />
            </div>
            <div className="column">
                <StyledImg image={imageData} alt="Marketing Automation" />
            </div>
        </div>
      </div>
    </Wrapper>
  )
}

export default MaIntro
