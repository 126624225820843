import styled from "styled-components"
import { GatsbyImage } from "gatsby-plugin-image"

export const StyledImg = styled(GatsbyImage)`
  width: 100%;
`

export const Wrapper = styled.div`
  position: relative;
  padding: 8rem 0 4rem;
  @media screen and (max-width: 991px) {
        padding: 2rem 0 0rem;
    }
  .container{
    .columns{
        width: calc(100% + 0.75rem);
        align-items: center;
        @media screen and (max-width: 991px) {
            flex-direction: column-reverse;
            flex-wrap: wrap;
            width: 100%;
        }
        .column{
            :nth-child(1) {
                > div {
                    padding-right: 3rem;
                    @media screen and (max-width: 991px) {
                        padding-right: 0;
                    }
                }
                h1 {
                    line-height: 1em;
                    margin-bottom: 1rem;
                    margin-top: 0;
                }
            }
            :nth-child(2) {
                max-width: 50%;
                padding-right: 0;
                @media screen and (max-width: 991px) {
                    max-width: 100%;
                    width: 100%;
                }
                img {
                    width: 100%;
                    @media screen and (max-width: 991px) {
                        padding: 0;
                    }
                }
            }
        }
    }
    p {
        color: #7A757E;
        font-size: 18px;
        line-height: 1.6em;
    }
    ul {
        color: #7A757E;
        font-size: 18px;
        line-height: 1.6em;
        margin-bottom: 0;
        li {
            margin: 0;
        }
    }
  }
`
