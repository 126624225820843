import React from "react"
import { getImage } from "gatsby-plugin-image"
import { useMaImageSectionRightQuery } from "../../../hooks/useMaImageSectionRightQuery"
import { Wrapper, StyledImg } from "./MaImageSectionRight.styles"

const MaImageSectionRight = () => {
  
  const {
    wpPage: { marketingAutomation: data },
  } = useMaImageSectionRightQuery()

  const text = data.textL
  const imageData = getImage(data.imageR.localFile)
  const imageAlt = data.imageR.altText

  return (
    <Wrapper>
      <div className="container">
        <div className="columns">
            <div className="column">
                <div className="text-wrapper" dangerouslySetInnerHTML={{ __html: text }} />
            </div>
            <div className="column">
                <StyledImg image={imageData} alt={imageAlt} />
            </div>
        </div>
      </div>
    </Wrapper>
  )
}

export default MaImageSectionRight
