import React from 'react';
import Layout from '../../components/Layout/Layout';
import Seo from '../../components/Seo/Seo';
import MaHero from '../../components/MarketingAutomation/MaHero/MaHero';
import MaIntro from '../../components/MarketingAutomation/MaIntro/MaIntro';
import MaImageSectionLeft from '../../components/MarketingAutomation/MaImageSectionLeft/MaImageSectionLeft';
import MaImageSectionRight from '../../components/MarketingAutomation/MaImageSectionRight/MaImageSectionRight';
import LetsChat from '../../components/Home/LetsChat/LetsChat';

const MaPage = () => (
  <Layout>
    <Seo title="Marketing Automation" />
    <MaHero />
    <MaIntro />
    <MaImageSectionLeft />
    <MaImageSectionRight />
    <LetsChat />
  </Layout>
)


export default MaPage;
