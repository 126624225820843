import React from "react"
import { getImage } from "gatsby-plugin-image"
import { useMaImageSectionLeftQuery } from "../../../hooks/useMaImageSectionLeftQuery"
import { Wrapper, StyledImg } from "./MaImageSectionLeft.styles"

const MaImageSectionLeft = () => {
  
  const {
    wpPage: { marketingAutomation: data },
  } = useMaImageSectionLeftQuery()

  const text = data.textR
  const imageData = getImage(data.imageL.localFile)
  const imageAlt = data.imageL.altText

  return (
    <Wrapper>
      <div className="container">
        <div className="columns">
            <div className="column">
                <StyledImg image={imageData} alt={imageAlt} />
            </div>
            <div className="column">
                <div className="text-wrapper" dangerouslySetInnerHTML={{ __html: text }} />
            </div>
        </div>
      </div>
    </Wrapper>
  )
}

export default MaImageSectionLeft
