import styled from "styled-components"
import { GatsbyImage } from "gatsby-plugin-image"

export const StyledImg = styled(GatsbyImage)`
  width: 100%;
  img {
        object-fit: contain!important;
        @media (min-width:991px) {
            object-position: right;
        }
    }
`

export const Wrapper = styled.div`
  position: relative;
  padding: 2rem 0 6rem;
  @media screen and (max-width: 991px) {
        padding: 0 0 0rem;
    }
  .container{
    .columns{
        width: calc(100% + 0.75rem);
        align-items: center;
        @media screen and (max-width: 991px) {
            flex-direction: column-reverse;
            flex-wrap: wrap;
            width: 100%;
            padding-top: 1rem;
        }
        .column{
            :nth-child(1) {

                @media screen and (max-width: 991px) {
                    padding-left: 0;
                    padding-right: 0;
                    margin-bottom: 1.5rem;
                }
                .text-wrapper {
                    @media screen and (max-width: 991px) {
                        padding: 0;
                    }
                    h1 {
                        line-height: 1em;
                    }
                    h2{
                        margin: 0 0 1.25rem;
                    }
                    h3 {
                        color: #FF364E;
                        margin: 0 0 0.25rem;
                    }
                    p {
                        font-size: 18px;
                        color: #7A757E;
                        line-height: 1.6em;
                    }
                }
            }
            :nth-child(2) {
                @media screen and (max-width: 991px) {
                    max-width: 100%;
                    width: 100%;
                    margin-bottom: 1.5rem;
                }
                img {
                    width: 100%;
                    @media screen and (max-width: 991px) {
                        padding: 0;
                    }
                }
            }

        }
    }
  }
`
