import { useStaticQuery, graphql } from "gatsby"

export const useMaHeroQuery = () => {
  const data = useStaticQuery(graphql`
    query MaHeroQuery {
      wpPage(databaseId: {eq: 391}) {
        id
        marketingAutomation {
          hero {
            altText
            localFile {
              childImageSharp {
                gatsbyImageData(placeholder: BLURRED)
              }
            }
          }
          heroMask {
            altText
            localFile {
              publicURL
            }
          }
        }
      }
    }
  `)

  return data
}
