import { useStaticQuery, graphql } from "gatsby"

export const useMaImageSectionRightQuery = () => {
  const data = useStaticQuery(graphql`
    query MaImageSectionRightQuery {
        wpPage(databaseId: {eq: 391}) {
            id
            marketingAutomation {
              textL
              imageR {
                altText
                localFile {
                  childImageSharp {
                    gatsbyImageData(placeholder: BLURRED)
                  }
                }
              }
            }
          }
    }
  `)

  return data
}
